
import img from "../../images/works/Industriali.jpg";
import { pageStyles } from "../../styles/style";
import Service from "../../components/Service";
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Pulzie Industriali " prepageName="Servizio" postpageName="Pulizie Industriali"/>
      <Service
        title="Pulizie Industriali"
        text="Rivolgersi a MANUTENPUL Srls per le pulizie 
        industriali significa garantirsi interventi professionali 
        di sanificazione e disinfezione, effettuati esclusivamente 
        da operatori altamente qualificati e nel totale rispetto delle 
        norme di sicurezza. A prescindere dal settore di attività, l’igienizzazione 
        di un’azienda è un intervento molto particolare, che deve essere 
        affidato esclusivamente a imprese di pulizia strutturate, organizzate 
        e con comprovata esperienza in questo tipo di servizio. Per la pulizia 
        delle aree aziendali (uffici, depositi, reparti produttivi, capannoni...), 
        infatti, occorre prevedere un approccio esclusivo e specifico che comprende: 
        l’impiego di particolari attrezzature professionali adatte a gestire vaste superfici, 
        prodotti detergenti certificati e operatori altamente qualificati, in grado di gestire 
        le procedure con rapidità e in totale sicurezza."
        img={img}
      />
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
